import { showErrorDialog } from '@/mixins/utils';

export const HandleError = (error) => {
  const errorMessage = Array.isArray(error.data) ? error.data[0] : error.data;
  if (errorMessage) {
    showErrorDialog(
      'Error occurred',
      errorMessage
    );
  } else {
    showErrorDialog(
      'An unexpected error occurred',
      'Try again or let an administrator know if the problem persists.'
    );
  }
};
