import { render, staticRenderFns } from "./CompoundList.vue?vue&type=template&id=688f8e92&scoped=true&"
import script from "./CompoundList.vue?vue&type=script&lang=js&"
export * from "./CompoundList.vue?vue&type=script&lang=js&"
import style0 from "./CompoundList.vue?vue&type=style&index=0&id=688f8e92&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "688f8e92",
  null
  
)

export default component.exports