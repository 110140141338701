<template>
  <v-card class="pa-5">
    <v-form ref="form" @submit.prevent>
      <v-text-field
        v-model="project.name"
        label="プロジェクト名"
        :rules="[v => !!v || 'プロジェクト名を入力してください']"
        required
      />
      <v-textarea
        v-model="project.description"
        label="説明"
        :rules="[
          v => !v || v.length <= 200 || '説明は200文字までです',
          v => !v || (v.match(/\n/g) || []).length < 10 || '説明は10行までです'
        ]"
        required
        dense
      />
      <v-textarea
        v-model="project.memo"
        label="メモ"
        dense
      />
      <v-radio-group
        v-model="project.visibility"
        label="公開範囲："
        row
        dense
      >
        <v-radio
          v-for="(choice, index) in visibilityChoices"
          :key="index"
          :label="choice.label"
          :value="choice.value"
          dense
        />
      </v-radio-group>
      <v-select
        v-if="project.visibility === 'ToGroup'"
        v-model="project.group"
        :items="groupOptions"
        label="グループ"
        item-value="id"
        item-text="name"
        :rules="[(v) => project.visibility && !!v || 'Group is required']"
        required
        dense
      />
      <div
        v-if="project.visibility === 'ToGroup' && project.group && groupMembersListInfo"
        style="color: red;"
      >
        <span>以下のユーザーは結果が見えるようになります。</span>
        <TruncatedTextDisplay
          :full-text="groupMembersListInfo"
          :truncate-to="25"
        />
      </div>
    </v-form>

    <v-card-actions v-if="project.id">
      <v-btn @click="cancel">
        キャンセル
      </v-btn>
      <v-spacer />
      <v-btn color="primary" @click="onSubmit('save')">更新</v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <v-btn @click="cancel">
        キャンセル
      </v-btn>
      <v-spacer />
      <v-btn color="primary" @click="onSubmit('save')">作成</v-btn>
      <v-spacer />
      <v-btn
        rounded
        class="mr-2"
        color="cyan"
        @click="onSubmit('saveAndGo')"
      >
        新規デザインも設定
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { showErrorDialog, showSuccessDialog } from '@/mixins/utils';
import TruncatedTextDisplay from '@/components/TruncatedTextDisplay';

export default {
  name: 'ProjectForm',
  components: {
    TruncatedTextDisplay
  },
  props: {
    projectToEdit: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      visibilityChoices: [],
      groupOptions: [],
      project: {},
      groupMembersListInfo: ''
    };
  },
  watch: {
    'projectToEdit': {
      handler() {
        this.initializeProject();
      },
      deep: true
    },
    'project.group': {
      handler() {
        const self = this;
        if (this.project.group) {
          this.api.getGroupMembers(this.project.group, (members) => {
            self.groupMembersListInfo = members.data.map(m => m.username).join(', ');
          }, () => {
            self.groupMembersListInfo = '';
          });
        }
      }
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    this.initializeProject();
    const self = this;
    this.api.getVisibilityChioces(function(list) {
      self.visibilityChoices = list;
      self.project.visibility = self.projectToEdit ? self.projectToEdit.visibility : 'ToGroup';
    });
    this.groupOptions = this.$session.get('user').groups;
  },
  methods: {
    onSubmit(event) {
      if (this.$refs.form.validate()) {
        this.project.id ? this.updateProject(event) : this.createProject(event);
      }
    },
    createProject(event) {
      const self = this;
      this.api.registerProject(
        this.project,
        function(project) {
          self.$emit(event, project);
          self.$refs.form.reset();
          showSuccessDialog('プロジェクトが作成されました。');
        },
        function(error) {
          if (error.status !== 401) {
            showErrorDialog(
              '予想外のエラーが発生しました。',
              Array.from(new Set(
                Object.values(error.data)
                  .map(arr => arr[0]))
              ).join('<br>')
            );
          }
        }
      );
    },
    updateProject(event) {
      const self = this;
      this.api.patchProject(
        this.project.id,
        this.project,
        function(project) {
          self.$emit(event, project);
          self.$refs.form.reset();
          showSuccessDialog('プロジェクトが更新されました。');
        },
        function(error) {
          showErrorDialog(
            '予想外のエラーが発生しました。',
            Array.from(new Set(
              Object.values(error.data)
                .map(arr => arr[0]))
            ).join('<br>')
          );
        }
      );
    },
    cancel() {
      this.$emit('cancel');
    },
    initializeProject() {
      if (this.projectToEdit === null) {
        this.project = {
          visibility: 'ToGroup'
        };
        this.$refs.form.resetValidation();
      } else {
        this.project = {
          id: this.projectToEdit.id,
          name: this.projectToEdit.name,
          description: this.projectToEdit.description,
          memo: this.projectToEdit.memo,
          visibility: this.projectToEdit.visibility,
          group: this.projectToEdit.group
        };
      }
    }
  }
};
</script>
