<template>
  <v-container v-if="module">
    <v-row>
      <v-col>
        <h3 class="d-flex align-center justify-space-between m-0">
          <span class="d-flex align-center">
            {{ module.name }}
            <SettingsAssistantButton v-if="module.type === 'StructureGeneration'" />
          </span>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                class="icon mx-8"
                v-bind="attrs"
                v-on="on"
                @click="importFromJSON"
              >
                <v-icon>
                  mdi-file-import-outline
                </v-icon>
                <span>
                  Import parameters from JSON
                </span>
              </v-btn>
            </template>
            <span>
              JSONファイルを使用して一括入力を行う。<br>
              [ ]で囲まれたフィールド名と同じキーを使う。
            </span>
          </v-tooltip>
          <v-btn
            class="icon mx-8"
            :disabled="module.document_name == ''"
            @click="downloadDocument(module.id)"
          >
            <v-icon v-if="module.document_name != ''">
              mdi-file-download-outline
            </v-icon>
            <span v-if="module.document_name != ''">
              ドキュメントファイルのダウンロード
            </span>
            <span v-else>
              このモデルはドキュメントがありません
            </span>
          </v-btn>
        </h3>
        <div class="description-block">{{ descriptionShown }}</div>

        <ReadMoreSwitch
          v-if="module.description.split('\n').length > 3"
          @onClick="switchDescription"
        />
      </v-col>
    </v-row>
    <v-divider />
    <v-form :disabled="isSubmitting" @submit.prevent>
      <v-row>
        <v-col>
          <div
            v-for="(info, name) in module.config.input || {}"
            v-show="name!=='compound_identifier_name'"
            :key="name"
          >
            <ModuleInput
              ref="children"
              :name="name"
              :info="info"
              :is-sg-model-field="module.type === 'StructureGeneration'"
              :value="importJson[name]"
              @changeParameter="onChange"
              @setFile="onSetFile"
            />
          </div>
        </v-col>
      </v-row>
      <v-row v-if="module.has_linked_uncertainty">
        <v-col>
          <v-checkbox
            v-model="computeUncertainty"
            label="Compute Uncertainty"
            @change="onSelectComputeUncertainty"
          />
        </v-col>
      </v-row>
      <v-row v-if="module.rnn_volume">
        <v-col>
          <RnnVolumeSelection
            ref="rnnVolumeSelection"
            :value="importJson['rnn_model']"
            @select="onRnnVolumeSelect"
          />
        </v-col>
      </v-row>
      <v-row v-if="module.reward_config_file">
        <v-col>
          <div
            v-for="(info, name) in rewardSetting"
            :key="name"
          >
            <ModuleInput
              ref="children"
              :name="name"
              :info="info"
              :value="importJson[name]"
              :all-required="true"
              @changeParameter="onChange"
              @setFile="onSetFile"
            />
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-btn
      color="warning"
      :disabled="isSubmitting"
      :loading="isSubmitting"
      @click="onSubmit"
    >
      Submit
    </v-btn>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <ExecutionVisibility
        :choices="visibilityChoices"
        @cancel="dialog=false"
        @confirm="onConfirm"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import router from '../router';
import ModuleInput from '@/components/ModuleInput';
import ReadMoreSwitch from '@/components/ReadMoreSwitch';
import SettingsAssistantButton from '@/components/SettingsAssistantButton';
import Swal from 'sweetalert2';
import { createCSVFileFromEditor, showErrorDialog } from '@/mixins/utils';
import { notifyIfJobQueueFull } from '@/mixins/api_utils';
import { HandleError } from '@/mixins/ui_utils';
import ExecutionVisibility from '@/components/ExecutionVisibility';
import RnnVolumeSelection from '@/components/RnnVolumeSelection';
import consts from '@/store/consts';

export default {
  name: 'ExecuteModel',
  components: {
    ModuleInput: ModuleInput,
    ReadMoreSwitch,
    SettingsAssistantButton,
    ExecutionVisibility,
    RnnVolumeSelection
  },
  data() {
    return {
      id: this.$route.params.id,
      module: null,
      parameters: Object(),
      files: Object(),
      showFullDescription: false,
      isSubmitting: false,
      dialog: false,
      visibilityChoices: [],
      rewardSetting: { ...consts.RewardSetting },
      computeUncertainty: false,
      importJson: Object()
    };
  },
  computed: {
    descriptionShown() {
      return this.showFullDescription
        ? this.module.description
        : this.module.description.split('\n', 3).join('\n');
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);

    const self = this;
    this.api.getMLModule(this.id, function(module) {
      self.module = module;
      self.module.config = JSON.parse(self.module.config);
    }, function(error) {
      console.log(error);
    }, false, true);
    this.api.getExecutionVisibility(function(choices) {
      self.visibilityChoices = choices;
    });
  },
  methods: {
    onChange(...args) {
      const [name, value] = args;
      this.parameters[name] = value;
    },
    onSetFile(...args) {
      const [name, file] = args;
      this.files[name] = file;
    },
    validate() {
      if (this.module.rnn_volume && !this.$refs.rnnVolumeSelection.validatedForm()) {
        return false;
      }
      return this.$refs.children
        .map(child => child.validatedForm())
        .flat(Infinity)
        .every(valid => valid);
    },
    onSubmit() {
      if (this.validate()) {
        if (this.visibilityChoices.length > 0) {
          this.dialog = true;
        } else {
          showErrorDialog(
            'Something went wrong.',
            'Cannot get visibility options. Please contact an administrator.'
          );
        }
      }
    },
    downloadDocument(moduleId) {
      const self = this;
      this.api.downloadDocumentFile(
        moduleId,
        function(response) {
          if (response.headers['content-disposition'].includes('filename')) {
            const filename = decodeURI(
              response.headers['content-disposition'].split('filename=')[1]
            );
            self.api.downloadFileHelper(response, `${filename}`);
          } else { // Should never happen
            Swal.fire({
              icon: 'warning',
              title: 'No file for this model'
            });
          }
        },
        function() {
          showErrorDialog(
            'File not found',
            `This file could not be found.
              Try again or let an administrator know if the problem persists.`
          );
        },
        true
      );
    },
    switchDescription() {
      this.showFullDescription = !this.showFullDescription;
    },
    onConfirm(visibility) {
      this.dialog = false;
      if (this.$refs.children.find(child => child.name === 'compounds_file')?.isDrawnCompound) {
        // Accepted by convert_compounds_file_to_allowed_extension regardless of file type
        const file = createCSVFileFromEditor('kekule', this.parameters.compound_structure_name);
        this.onSetFile('compounds_file', file);
      }
      this.isSubmitting = true;

      notifyIfJobQueueFull(() => {
        const self = this;
        self.api.executeMLModule(
          { moduleId: self.id },
          { ...self.parameters, visibility },
          self.files,
          function(response) {
            router.push({ name: 'ExecutionView', params: { id: response.id } });
          },
          function(e) {
            self.isSubmitting = false;
            HandleError(e);
          }
        );
      });
    },
    onRnnVolumeSelect(volume) {
      this.parameters.rnn_volume = volume;
    },
    onSelectComputeUncertainty() {
      this.parameters.compute_uncertainty = this.computeUncertainty;
    },
    importFromJSON() {
      const self = this;
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = '.json';
      input.onchange = function(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = function(e) {
          self.importJson = JSON.parse(e.target.result);
        };
        reader.readAsText(file);
      };
      input.click();
    },
    openSettingAssistant() {
      window.open('https://duckduckgo.com/', '_blank');
    }
  }
};
</script>
