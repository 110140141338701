<template>
  <div>
    <p>
      <span>{{ truncatedText }}</span>
      <a
        v-if="!isExpanded && fullText.length > truncateTo"
        @click="isExpanded=true"
      >
        {{ showAllText[language] }}
      </a>
      <a
        v-if="isExpanded"
        @click="isExpanded=false"
      >
        {{ foldText[language] }}
      </a>
    </p>
  </div>
</template>
<script>

export default {
  name: 'TruncatedTextDisplay',
  props: {
    fullText: {
      type: String,
      default: ''
    },
    truncateTo: {
      type: Number,
      required: true
    },
    language: {
      type: String,
      default: 'ja'
    }
  },
  data() {
    return {
      isExpanded: false,
      showAllText: {
        ja: 'すべて表示',
        en: 'Show all'
      },
      foldText: {
        ja: '折りたたむ',
        en: 'Show less'
      }
    };
  },
  computed: {
    truncatedText() {
      if (this.isExpanded) {
        return this.fullText;
      } else {
        return this.fullText.slice(0, this.truncateTo) +
          (this.fullText.length > this.truncateTo ? '...' : '');
      }
    }
  },
  methods: {
  }
};
</script>
