<template>
  <v-container>
    <v-card>
      <v-card-title>
        Test history
        <v-spacer />
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
              @click="refresh"
            >
              <v-icon dark>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :items="flatTests"
          :headers="headers"
          class="elevation-1"
          hide-default-footer
          disable-pagination
        >
          <template
            v-for="(column, i) in headerValues"
            #[`item.${column}`]="{ item }"
          >
            <v-tooltip
              v-if="column == 'folder_download'"
              :key="`download_${i}`"
              top
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  :loading="isDownloading"
                  class="mr-2"
                  v-on="on"
                  @click="downloadWorkFolder(item.id)"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Download working folder.</span>
            </v-tooltip>

            <template v-if="column == 'status'">
              <v-icon
                v-if="item.status == 'Success'"
                :key="`status_${i}`"
                color="green"
                class="d-flex justify-center"
              >
                mdi-check-circle
              </v-icon>
              <v-icon
                v-else-if="item.status == 'Fail'"
                :key="`close_status_${i}`"
                color="red"
                class="d-flex justify-center"
              >
                mdi-close-circle
              </v-icon>
              <v-icon
                v-else-if="item.status == 'Unknown'"
                :key="`help_status_${i}`"
                class="d-flex justify-center"
              >
                mdi-help-circle
              </v-icon>
              <v-icon
                v-else-if="item.status == 'Canceled'"
                :key="`stop_status_${i}`"
                color="black"
                class="d-flex justify-center"
              >
                mdi-stop-circle-outline
              </v-icon>
            </template>
            <template
              v-else-if="column == 'output_files'"
            >
              <div
                v-for="(output, index) in item[column]"
                :key="`output_${index}`"
                class="downloadLink ml-3"
                @click="downloadFile(item.id, item[column][index])"
              >
                {{ item[column][index] }}
              </div>
            </template>
            <span
              v-else-if="item[column] && item[column]['type']"
              :key="`dl_${i}`"
              class="downloadLink"
              @click="downloadFile(item.id, item[column]['name'])"
            >
              {{ item[column]["name"] }}
            </span>
            <span
              v-else-if="column == 'status_file'"
              :key="`statusfile_${i}`"
              class="downloadLink"
              @click="downloadFile(item.id, item.status_file)"
            >
              {{ item[column] }}
            </span>
            <template
              v-else-if="column == 'outputs'"
            >
              <div
                v-for="(output, output_index) in outputs"
                :key="`${i}_${output_index}`"
                class="downloadLink"
                @click="downloadFile(item.id, item[output].name)"
              >
                {{ item[output].name }}
              </div>
            </template>
            <span v-else-if="item[column]" :key="i">
              {{ item[column] }}
            </span>
            <template
              v-else-if="column == 'action'"
            >
              <CancelExecutionButton
                v-if="isCancelableExecution(item)"
                :key="`action_${i}`"
                :execution-id="item.id"
                execution-type="test"
                @refresh="refresh"
              />
            </template>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card class="mt-5">
      <v-card-title>Test settings</v-card-title>
      <v-card-text>
        <h3 class="ml-4">
          Argument(s)
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <sup>
                <v-icon dense small v-bind="attrs" v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </sup>
            </template>
            <span>
              モデル作成時に登録された項目。
            </span>
          </v-tooltip>
        </h3>
        <v-form ref="form" @submit.prevent>
          <v-row v-for="(input, i) in inputs" :key="`input_${i}`">
            <v-col cols="3">
              <v-subheader>{{ input.name }}</v-subheader>
            </v-col>
            <v-col cols="9">
              <ModuleInput
                ref="children"
                :name="input.name"
                :info="input"
                :all-required="true"
                :reward-config="input.name==='reward_config'"
                @changeParameter="onChange"
                @setFile="onSetFile"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-text>
        <h3 class="ml-4">
          Expected output
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <sup>
                <v-icon dense small v-bind="attrs" v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </sup>
            </template>
            <span>
              上記の条件で期待される結果。
            </span>
          </v-tooltip>
        </h3>
        <v-form ref="form" @submit.prevent>
          <v-row v-for="(output, i) in outputs" :key="`output_${i}`">
            <v-col cols="3">
              <v-subheader>{{ output }}</v-subheader>
            </v-col>
            <v-col cols="9">
              <ModuleInput
                ref="children"
                :name="output"
                :info="{ type: 'file', description: output }"
                @changeParameter="onChange"
                @setFile="onSetFile"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider class="mt-5" />
      <v-card-actions>
        <v-btn text @click="clearForm"> Clear form </v-btn>
        <v-spacer />
        <v-btn color="primary" text dense @click="addSingleTest">
          Create test
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mt-5">
      <v-card-title>Read test settings from a ZIP file</v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent>
          <v-row no-gutters>
            <v-col cols="9">
              <v-file-input
                v-model="archive"
                label="Test archive (.zip)"
                accept=".zip"
              />
            </v-col>
            <v-col cols="3">
              <div class="pt-3 mt-1 text-center">
                <v-btn color="primary" text @click="addTestSuite">
                  Create Tests
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CancelExecutionButton from '@/components/CancelExecutionButton.vue';
import ModuleInput from '@/components/ModuleInput';
import { showErrorDialog, showSuccessDialog } from '@/mixins/utils';
import { HandleError } from '@/mixins/ui_utils';
import { downloadWorkFolder, notifyIfJobQueueFull } from '@/mixins/api_utils';

export default {
  name: 'ModelTest',
  components: {
    ModuleInput: ModuleInput,
    CancelExecutionButton
  },
  data() {
    return {
      id: this.$route.params.id,
      archive: null,
      inputs: [],
      outputs: [],
      tests: [],
      parameters: Object(),
      files: Object(),
      currentUser: this.$session.get('user'),
      isDownloading: false
    };
  },
  computed: {
    headers: function() {
      let headers = [
        {
          text: 'Status',
          value: 'status',
          sortable: false
        },
        {
          text: 'Status Reason',
          value: 'status_reason',
          sortable: false
        }
      ];
      for (const input of this.inputs) {
        headers.push({ text: input.name, value: input.name });
      }
      headers = headers.concat([
        { text: 'Expected output', value: 'outputs' },
        { text: 'Execution (start)', value: 'begin_at' },
        { text: 'Execution (end)', value: 'end_at' },
        { text: 'Calculated output', value: 'output_files' },
        { text: 'Status File', value: 'status_file' },
        { text: 'Action', value: 'action', sortable: false },
        { text: 'Action', value: 'folder_download', sortable: false }
      ]);
      return headers;
    },
    headerValues: function() {
      return this.headers.map((h) => h.value);
    },
    flatTests: function() {
      const tests = this.tests;
      let i = 0;
      for (; i < tests.length; i++) {
        for (const input of this.tests[i].inputs) {
          if (input.type === 'file') {
            tests[i][input.name] = {
              name: input.value,
              type: input.type
            };
          } else {
            tests[i][input.name] = input.value;
          }
        }
      }
      return tests;
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);

    this.$session.start();
    const self = this;

    this.fetchAllTests();
    this.api.getAIModelInputs(
      this.id,
      function(inputs) {
        self.inputs = inputs;
      },
      function(error) {
        console.log(error);
      }
    );
    this.api.getAIModelOutputs(
      this.id,
      function(outputs) {
        self.outputs = outputs;
      },
      function(error) {
        console.log(error);
      }
    );
  },
  methods: {
    downloadWorkFolder(testId) {
      this.isDownloading = true;
      downloadWorkFolder(testId, 'test_execution');
      this.isDownloading = false;
    },
    refresh() {
      this.fetchAllTests();
    },
    fetchAllTests() {
      const self = this;
      this.api.getMLTests(
        this.id,
        function(tests) {
          self.tests = tests;
        },
        function(error) {
          console.log(error);
        }
      );
    },
    clearForm() {
      this.$refs.children.forEach(childForm => childForm.clearForm());
    },
    validate() {
      return this.$refs.children
        .map(child => child.validatedForm())
        .flat(Infinity)
        .every(valid => valid);
    },
    onChange(...args) {
      const [name, value] = args;
      this.parameters[name] = value;
    },
    onSetFile(...args) {
      const [name, file] = args;
      if (file) {
        this.files[name] = file;
      }
    },
    addSingleTest() {
      if (this.validate()) {
        notifyIfJobQueueFull(() => {
          this.parameters.module_id = this.id;
          const self = this;
          this.api.postMLTest(
            this.parameters,
            this.files,
            function() {
              self.fetchAllTests();
              showSuccessDialog(
                'Test added',
                'Your test will be executed soon.'
              );
            },
            function(e) {
              HandleError(e);
            }
          );
        });
      }
    },
    addTestSuite() {
      console.log('Add Test Suite');
    },
    downloadFile(testId, filename) {
      const self = this;
      this.api.downloadTestFile(
        testId,
        filename,
        function(response) {
          self.api.downloadFileHelper(response, filename);
        },
        function() {
          showErrorDialog(
            'File not found',
            `This file could not be found.
              Try again or let an administrator know if the problem persists.`
          );
        }
      );
    },
    isCancelableExecution(execution) {
      const isExecutor = execution.executor === this.currentUser.id;
      const isCancelableStatus = (execution.end_at === null) &&
                                (execution.status !== 'Canceled');
      return isExecutor && isCancelableStatus;
    }
  }
};
</script>
