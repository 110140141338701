<template>
  <div class="border-box">
    <div v-if="isRewardInputProp">
      <h3>Reward {{ index + 1 }}</h3>
      <hr class="custom-line">
    </div>
    <div v-for="(info, name) in elementLocal" :key="name">
      <ModuleInput
        ref="children"
        :name="name"
        :info="info"
        :all-required="allRequired"
        :value="item[name]"
        :reward-items="rewardItems"
        :is-reward-input-prop="isRewardInputProp"
        :is-sg-model-field="isSgModelField"
        @changeParameter="onChangeChild"
        @setFile="onSetFile"
        @rewardSelect="onRewardSelect"
        @scalingSelect="changeFieldsDependsOnScaling"
      />
    </div>
    <div v-for="(info, name) in rewardInputs" :key="name">
      <ModuleInput
        :name="name"
        :info="info"
        :all-required="false"
        :value="item[name]"
        :reward-items="rewardItems"
        :is-reward-input-prop="isRewardInputProp"
        :is-sg-model-field="isSgModelField"
        @changeParameter="onChangeChild"
        @setFile="onSetFile"
      />
    </div>
  </div>
</template>

<script>
import consts from '@/store/consts';

export default {
  name: 'ModuleInputBlock',
  components: {
    'ModuleInput': () => import('../components/ModuleInput')
  },
  props: {
    element: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    listIndex: {
      type: Number,
      default: -1
    },
    allRequired: {
      type: Boolean,
      default: false
    },
    rewardItems: {
      type: Array,
      default: () => []
    },
    // Keep the "Prop" suffix for this variable
    isRewardInputProp: {
      type: Boolean,
      default: false
    },
    isSgModelField: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      elementLocal: {},
      rewardInputs: {}
    };
  },
  mounted() {
    // The default of good_value is set to fit the "Threshold" scaling
    // Use consts.RewardGoodValueFields to correct the value to the correct one.
    if (this.item.scaling) {
      this.elementLocal = {
        ...this.element,
        'good_value': consts.RewardGoodValueFields[this.item.scaling]
      };
    } else {
      const { good_value, ...rest } = this.element;
      this.elementLocal = { ...rest };
    }
  },
  methods: {
    onChangeChild(name, value) {
      this.$emit('changeParameter', name, value, this.index);
    },
    onSetFile(name, value) {
      this.$emit('setFile', name, value, this.index);
    },
    onRewardSelect(rewardId) {
      const self = this;
      this.$emit('rewardChanged', Object.keys(this.rewardInputs), this.index);
      this.rewardInputs = {};
      this.api.getMLModule(rewardId, function(module) {
        self.rewardInputs = JSON.parse(module.config).input || {};
        self.addRewardObjectiveValue(module);
      }, function(error) {
        console.log(error);
      }, false, true);
    },
    addRewardObjectiveValue(objServer) {
      const objVals = objServer.objective_values;
      if (objVals && objVals.length > 0) {
        this.elementLocal = {
          ...this.elementLocal,
          'objective_value': {
            'type': 'select',
            'description': 'Objective value',
            'options': objVals.map(val => ({ text: val, value: val })),
            'default': objVals[0],
            'is_reward': true
          }
        };
      } else {
        this.elementLocal = { ...this.elementLocal, 'objective_value': this.element.objective_value };
      }
    },
    changeFieldsDependsOnScaling(scaling) {
      if (scaling === 'none' || scaling === '') {
        delete this.elementLocal.threshold;
        this.elementLocal = {
          ...this.elementLocal,
          'good_value': this.element.good_value
        };
        this.$emit('rewardScalingChanged', null, this.index);
      } else if (scaling) {
        const thresholdFields = consts.RewardThresholdFields[scaling];
        this.elementLocal = {
          ...this.elementLocal,
          'good_value': consts.RewardGoodValueFields[scaling],
          'threshold': thresholdFields
        };
        this.$emit('rewardScalingChanged', thresholdFields.element, this.index);
      }
    },
    validatedForm() {
      return this.$refs.children
        .map(child => child.validatedForm())
        .flat(Infinity)
        .every(valid => valid);
    }
  }
};
</script>

<style scoped>
    .indent {
        margin-left: 30px;
    }
    .custom-line {
      border: none;
      border-top: 2px solid;
    }
    input {
        border: solid 1px;
    }
</style>
