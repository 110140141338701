<template>
  <v-card class="pa-5">
    <v-card-title>
      Link "{{ moduleToLink.name }}" to
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col v-if="isPredictionUncertaintyModule">
          <div class="text-subtitle-1">Objective Server</div>
          <v-autocomplete
            v-model="targetOsId"
            :items="linkableOS"
            item-value="id"
            item-text="name"
            :disabled="linkedOs.length > 0"
            required
          />
          <v-row v-for="item in linkedOs" :key="item.id" align="center">
            <v-col cols="10">
              <span>Linked with OS: {{ item.os.id }} - {{ item.os.name }}</span>
            </v-col>
            <v-col cols="2">
              <v-btn icon color="red" @click="unlinkOs(item.linkId)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-divider v-if="isPredictionUncertaintyModule" vertical />
        <v-col>
          <div class="text-subtitle-1">{{ linkTargetLabel }}</div>
          <v-autocomplete
            v-model="targetModelId"
            :items="linkableModules"
            :multiple="isPredictionModule"
            item-value="id"
            item-text="name"
            :disabled="(isObjectiveServer || isUncertantyModule) && linkedModule.length > 0"
            required
          />
          <v-row v-for="item in linkedModule" :key="item.id" align="center">
            <v-col cols="10">
              <span>Linked with module: {{ item.module.id }} - {{ item.module.name }}</span>
            </v-col>
            <v-col cols="2">
              <v-btn icon color="red" @click="unlinkModule(item.linkId)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="alert" @click="cancel">Cancel</v-btn>
      <v-spacer />
      <v-btn
        color="warning"
        :disabled="disableSubmit"
        @click="submit"
      >
        Link
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  modelLinkableOs,
  modelLinkableUncertainty,
  modelLinkableModules
} from '@/mixins/api_utils';
import {
  showSuccessDialog,
  showErrorDialog
} from '@/mixins/utils';
import consts from '@/store/consts';

export default {
  name: 'ModelLinkUnlinkDialog',
  components: {
  },
  props: {
    moduleToLink: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      linkableOS: [],
      linkableModules: [],
      targetModelId: null,
      targetOsId: null,
      linkedOs: [],
      linkedModule: []
    };
  },
  computed: {
    disableSubmit: function() {
      return this.targetModelId === null &&
        this.targetOsId === null;
    },
    isPredictionModule: function() {
      return consts.FeaturePredTypes.includes(this.moduleToLink.type);
    },
    isUncertantyModule: function() {
      return consts.UncertainTypes.includes(this.moduleToLink.type);
    },
    isPredictionUncertaintyModule: function() {
      return consts.PredAndUncertainTypes.includes(this.moduleToLink.type);
    },
    isObjectiveServer: function() {
      return this.moduleToLink.type === 'ObjectiveServer';
    },
    linkTargetLabel: function() {
      if (this.isPredictionModule) {
        return 'Uncertainty Models';
      }
      if (this.isUncertantyModule) {
        return 'Prediction Models';
      }
      return 'Prediction/Uncertainty Models';
    }
  },
  watch: {
    moduleToLink: async function() {
      await this.refresh();
    }
  },
  async mounted() {
    if (this.moduleToLink) {
      await this.setLinkables();
      this.fetchLinkStatus();
    }
  },
  methods: {
    cancel() {
      this.resetSelection();
      this.$emit('cancel');
    },
    async submit() {
      try {
        await this.linkModuleOS();
        if (this.isPredictionUncertaintyModule) {
          await this.linkModuleUncertainty();
        }
        await this.refresh();
      } catch (error) {
        showErrorDialog(
          "Couldn't link models.",
          'Please contact an administrator.');
      } finally {
        this.resetSelection();
      }
    },
    async refresh() {
      await this.setLinkables();
      this.fetchLinkStatus();
    },
    async setLinkables() {
      if (this.isPredictionUncertaintyModule) {
        this.linkableOS = await modelLinkableOs(this.moduleToLink.id);
      } else {
        // When type is OS
        this.linkableModules = await modelLinkableModules(this.moduleToLink.id);
      }
      if (this.isPredictionModule) {
        this.linkableModules = await modelLinkableUncertainty(
          this.moduleToLink.id);
      }
      if (this.isUncertantyModule) {
        this.linkableModules = await modelLinkableModules(this.moduleToLink.id);
      }
    },
    async linkModuleOS() {
      const param = {
        module: this.isObjectiveServer
          ? this.targetModelId
          : this.moduleToLink.id,
        objective_server: this.isObjectiveServer
          ? this.moduleToLink.id
          : this.targetOsId
      };
      if (Object.values(param).includes(null)) {
        return;
      }
      this.api.postModelsLink(
        param,
        function() {},
        function() {
          throw new Error("Couldn't link models.");
        });
    },
    async linkModuleUncertainty() {
      const param = {
        prediction_id: this.isPredictionModule
          ? this.moduleToLink.id
          : this.targetModelId,
        uncertainty_ids: this.isPredictionModule
          ? this.targetModelId || [] // targetModelId can be null
          : [this.moduleToLink.id]
      };
      if (param.prediction_id === null || param.uncertainty_ids.length === 0) {
        return;
      }
      this.api.postPredictionUncertaintyLink(
        param,
        function() {},
        function() {
          throw new Error("Couldn't link models.");
        });
    },
    async unlinkOs(linkId) {
      await this.api.deleteModelsLink(linkId, function() {
        showSuccessDialog('The models were successfully unlinked.');
      }, function() {
        showErrorDialog(
          "Couldn't unlink models.",
          'Please contact an administrator.');
      });
      await this.refresh();
    },
    async unlinkModule(linkId) {
      if (this.isObjectiveServer) {
        await this.unlinkOs(linkId);
      } else {
        await this.api.deletePredictionUncertainty(linkId, function() {
          showSuccessDialog('The models were successfully unlinked.');
        }, function() {
          showErrorDialog(
            "Couldn't unlink models.",
            'Please contact an administrator.');
        });
      }
      await this.refresh();
    },
    fetchLinkStatus() {
      this.fetchModelOsLink();
      this.fetchPredictionUncertaintyLink();
    },
    fetchModelOsLink() {
      let param = {};
      if (this.isObjectiveServer) {
        param = { os_id: this.moduleToLink.id };
      } else if (this.isPredictionUncertaintyModule) {
        param = { module_id: this.moduleToLink.id };
      } else {
        return;
      }
      this.api.getModelOSLinks(
        param,
        (data) => {
          const linkIds = data.map((m) => m.id);
          if (this.isPredictionUncertaintyModule) {
            const linkedOsIds = data.map((m) => m.objective_server);
            this.linkedOs = this.linkableOS
              .filter(os => linkedOsIds.includes(os.id))
              .map(os => ({ os, linkId: linkIds[linkedOsIds.indexOf(os.id)] }));
            this.linkableOS = this.linkableOS.filter(
              os => !linkedOsIds.includes(os.id));
          }
          if (this.isObjectiveServer) {
            const linkedModuleIds = data.map((m) => m.module);
            this.linkedModule = this.linkableModules
              .filter(m => linkedModuleIds.includes(m.id))
              .map(m => (
                {
                  module: m,
                  linkId: linkIds[linkedModuleIds.indexOf(m.id)]
                }));
            this.linkableModules = this.linkableModules
              .filter(m => !linkedModuleIds.includes(m.id));
          }
        });
    },
    fetchPredictionUncertaintyLink() {
      let param = {};
      if (this.isPredictionModule) {
        param = { prediction_id: this.moduleToLink.id };
      } else if (this.isUncertantyModule) {
        param = { uncertainty_id: this.moduleToLink.id };
      } else {
        return;
      }
      this.api.getPredictionUncertaintyLinks(
        param,
        (data) => {
          let linkedModuleIds = [];
          const linkIds = data.map((m) => m.id);
          if (this.isPredictionModule) {
            linkedModuleIds = data.map((m) => m.uncertainty_module);
          } else if (this.isUncertantyModule) {
            linkedModuleIds = data.map((m) => m.prediction_module);
          }
          this.linkedModule = this.linkableModules
            .filter(m => linkedModuleIds.includes(m.id))
            .map(m => ({
              module: m,
              linkId: linkIds[linkedModuleIds.indexOf(m.id)]
            }));
          this.linkableModules = this.linkableModules
            .filter(m => !linkedModuleIds.includes(m.id));
        });
    },
    resetSelection() {
      this.targetModelId = null;
      this.targetOsId = null;
    }
  }
};
</script>

<style scoped>
.close-btn {

  top: 5px;
  right: 5px;
}
</style>
